.mdx {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.mdx p {
  line-height: 2;
  font-size: var(--chakra-fontSizes-xl);
}

.mdx ul {
  padding-left: var(--chakra-space-6);
  font-size: var(--chakra-fontSizes-xl);
}

.mdx h2 {
  font-size: var(--chakra-fontSizes-2xl);
  font-weight: bold;
}
