html,
body {
  height: auto !important;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  /*background: #F7FAFC !important;*/
}

html {
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

.filepond--file-info {
  display: none !important;
}

.filepond--image-preview {
  background: #edf2f6 !important;
}

.filepond--image-preview-overlay-idle {
  color: transparent !important;
}

.filepond--action-remove-item {
  cursor: pointer !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
